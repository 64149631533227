<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Tables
      no-search
      :headers="headers"
      :items="getComments"
      :loading="getCommentsAreLoading"
      @change:search="SET_SEARCH"
    >
      <template #username="{ item }">
        <router-link :to="`/user/${item.userId}`">{{
          item.username
        }}</router-link>
      </template>
      <template #productTitle="{ item }">
        <router-link :to="`/product/${item.productId}`">{{
          item.productTitle
        }}</router-link>
      </template>
      <template #createdAt="{ item }">
        <small>{{ formatter(new Date(item.createdAt)) }}</small>
      </template>
      <template #point="{ item }">
        <span class="text-success" v-for="i in item.point" :key="'m' + i"
          >★</span
        >
        <span v-for="i in 5 - item.point" :key="'v' + i">☆</span>
      </template>
      <template #options="{ item }">
        <button
          @click="
            $router.replace({
              query: {
                ...$route.query,
                modal: `comment-${item.id}`,
              },
            })
          "
          class="btn btn-pill donate mx-1"
        >
          {{ $t("view") }}
        </button>
      </template>
    </Tables>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <CommentActionModal :comments="getComments"></CommentActionModal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import CommentActionModal from "../Comments/CommentActionModal.vue";
export default {
  components: { Tables, BoostrapPagination, CommentActionModal },
  props: {
    user: {
      default: () => {
        return {
          fullname: "",
        };
      },
    },
  },
  data() {
    return {
      search: "",
      page: 1,
      limit: 10,
      headers: [
        {
          col: 12,
          md: 4,
          lg: 2,
          xl: 2,
          title: this.$t("commenter"),
          value: "username",
        },
        {
          col: 12,
          md: 8,
          lg: 2,
          xl: 2,
          title: this.$t("product"),
          value: "productTitle",
        },
        {
          col: 12,
          md: 8,
          lg: 7,
          xl: 7,
          title: this.$t("comment"),
          value: "text",
        },
        {
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
          title: this.$t("point"),
          value: "point",
        },
        {
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
          title: this.$t("createdAt"),
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    getCommentsAreLoading() {
      return this.$store.state.Comments.commentsAreLoading;
    },
    getComments() {
      const comments = this.$store.state.Comments.commentsWithFilter.find(
        (c) => c.userId === this.user.id && c.page === this.page
      );
      if (comments) return comments.data;
      else return [];
    },
    pages() {
      const comments = this.$store.state.Comments.commentsWithFilter.find(
        (c) => c.userId === this.user.id
      );
      if (comments) return Math.ceil(comments.count / this.limit);
      else return 0;
    },
  },
  methods: {
    formatter,
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    SET_DATE(d) {
      const date = new Date(d);
      return formatter(date);
    },
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST();
    },
    GET_LIST() {
      this.$store.dispatch("GET_COMMENTS_WITH_FILTER", {
        page: this.page,
        filter: `userId=${this.user.id}&`,
        limit: 10,
        key: "userId",
        dataFilter: {
          userId: this.user.id,
          productId: -1,
        },
      });
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>
